<template>
	<div>
		<a-modal :title="getTitle" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="填单分类" >
							<a-input v-model="form.name" placeholder="请输入分类名称" style="width: 300px;" :suffix="form.name.length + '/5'" :maxLength="5"></a-input>
						</a-form-model-item>
						
						<a-form-model-item required label="单次提成" >
							<a-input-number v-model="form.single_commission"  :formatter="value => `${value}元`" placeholder="请输入单次业绩提成" :precision="2" :min="0" style="width: 300px;"/>
						</a-form-model-item>
					</a-form-model>	
				
					
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			performance_type_id:{
				type:Number,
				default:0
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: { span: 5 },
				wrapperCol: { span: 19 },
				form:{
					name:'',
					single_commission:'',
				}
			}
		},
		computed: {
			getTitle() {
				if (this.performance_type_id == 0) {
					return "添加业绩填单分类";
				} else {
					return "编辑业绩填单分类"
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.performance_type_id==0) return;
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPerformanceType',{
					performance_type_id:this.performance_type_id,
				}).then(res=>{
					if(res.form){
						this.form=res.form;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/savePerformanceType',{
					performance_type_id:this.performance_type_id,
					name:this.form.name,
					single_commission:this.form.single_commission,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$emit('ok');
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>
	
</style>
